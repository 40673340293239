import { render, staticRenderFns } from "./SectionUniverseMap.vue?vue&type=template&id=3971904d&"
import script from "./SectionUniverseMap.vue?vue&type=script&lang=js&"
export * from "./SectionUniverseMap.vue?vue&type=script&lang=js&"
import style0 from "./SectionUniverseMap.vue?vue&type=style&index=0&id=3971904d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/SectionUniverse.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fsections%2Flanding%2FSectionUniverseMap.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesCommonMoleculeGoogleMap: require('/app/components/molecules/common/MoleculeGoogleMap.vue').default,MoleculesMoleculeUniverseOrientationBar: require('/app/components/molecules/MoleculeUniverseOrientationBar.vue').default,MoleculesMoleculeFilterTriggerUniverse: require('/app/components/molecules/MoleculeFilterTriggerUniverse.vue').default,MoleculesProjectMoleculePopupHeader: require('/app/components/molecules/project/MoleculePopupHeader.vue').default,MoleculesProjectMoleculePopupResults: require('/app/components/molecules/project/MoleculePopupResults.vue').default,OrganismsOrganismFilterUniverse: require('/app/components/organisms/OrganismFilterUniverse.vue').default})
