
import baseConstants from '~/store/base/-constants'

export default {
  name: 'SectionUniverseMap',
  props: {
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    data: {
      required: true,
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
      focusView: false,
      buildingViewText: ''
    }
  },
  beforeMount() {
    this.changeBuildingViewText()
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener)
    if (window.innerWidth < 768) {
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_FILTER))
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    resizeListener(e) {
      if (window.innerWidth > 767) {
        this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.SHOW_FILTER))
      } else {
        this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_FILTER))
      }
      this.windowHeight = window.innerHeight
    },
    changeBuildingViewText() {
      if (window.innerWidth < 1200) {
        this.buildingViewText =
          'You can view more details about the project by tapping on the filter or info button'
      } else {
        this.buildingViewText = 'Press escape or back to map button to go back'
      }
    }
  }
}
